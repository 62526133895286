
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import StatusService from "@/services/work/status.service";
import core from "@/core";
import { DetailModel, StatusModel } from "@/models/status/status.model";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import { AppBarMenuItem } from "@/types";
import Constant from "@/store/constant";
import ScheduleService from "@/services/work/schedule.service";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "StatusView",

  data: () => ({
    title: "시공현황",
    request: {
      statusId: null as number | null,
    },
    ready: false,
    status: {} as StatusModel,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
  }),
  mounted() {
    // viewer 뒤로가기시 닫힘 처리
    const routerBeforeTask = this.$store.state.app.routerBeforeTask;
    const routerBeforeTaskItem = new RouterBeforeTaskItem(this.$vnode.tag as string, () => {
      const viewerContainer = document.querySelector(".viewer-container.viewer-in");
      if (viewerContainer != null) {
        const button = viewerContainer.querySelector(".viewer-button") as any;
        if (button != null) {
          button.click();
        }
        return false;
      }
      return true;
    });
    routerBeforeTask.pushPage(routerBeforeTaskItem);

    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.request.statusId = params.id;
      await this.getStatus();
    });
  },
  activated() {
    //console.log("activated");
    const event = this.getPageUpdateEvent();
    //console.log("event : ", event);
    if (event != null) {
      this.getStatus();
      this.updatePreVmEvent(event.result, event.item);
    }
  },
  methods: {
    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "edit") {
        this.showEditPage();
      } else if (menu.id === "download") {
        this.downloadImage();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    async downloadImage() {
      if (this.status) {
        try {
          const status = this.status as StatusModel;
          //console.log("statusId : ", status.id);
          const response = (await StatusService.getStatusImageFile(status.id)) as any;
          //console.log("response : ", response);
          core.loader.show("다운로드중...");
          await core.http.downloadFile(response.downloadUri + "?v=" + new Date().getTime());
        } catch (e) {
          console.log(e);
        } finally {
          core.loader.hide();
        }
      }
    },
    imageHeight(item) {
      let height = 0;
      if (item.imageWidth && item.imageHeight) {
        const size = this.app.size;
        const cal = (size.width - 10) / item.imageWidth;
        height = item.imageHeight * cal;
      }
      return height;
    },
    onView(detail) {
      const imageUri = detail.imageUri;
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(imageUri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }
      // window.open(detail.imageUri, "_blank");
    },
    showEditPage() {
      this.$router.push({
        path: "/status/edit",
        query: {
          statusId: String(this.request.statusId),
        },
      });
    },
    async getStatus() {
      try {
        core.loader.show();
        this.status = (await StatusService.getStatus(
          this.request.statusId as number
        )) as StatusModel;
        // console.log("status : ", this.status);
        // console.log("status : ", this.status);
        const schedule = (await ScheduleService.get(this.status.scheduleId)) as any;
        if (schedule.category && schedule.category.name === "줄눈" && this.status.type === "A") {
          const customItems = [
            {
              imageUri: "/img/etc/grout_notice2.jpg?v=20240305",
              imageWidth: 1000,
              imageHeight: 1849,
            },
            { imageUri: "/img/etc/grout_notice.png", imageWidth: 2835, imageHeight: 3870 },
          ];
          customItems.forEach((customItem) => {
            const detail = {
              remark: "",
              fileDetailId: 0,
              imageThumbUri: customItem.imageUri,
              imageUri: customItem.imageUri,
              imageWidth: customItem.imageWidth,
              imageHeight: customItem.imageHeight,
            } as DetailModel;
            this.status.detailList.unshift(detail);
          });
        }
        if (this.status.type === "F") {
          this.title = "하자현황";
        } else if (this.status.type === "S") {
          this.title = "색상선택현황";
        } else {
          this.title = "시공현황";
        }
        this.ready = true;
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      core.loader.hide();
    },
  },
});
